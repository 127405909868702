<script>
import { SuawInputGroup, SuawHeading, SuawEmptyState, SuawTable, SuawTableItem, SuawButton, SuawTextInput } from "@suaw/suaw-component-library";
import { GetUserViewForAdminComplaintsProfile } from "../../operations.gql";
import * as UserApi from "@/features/users/api.js";
import { formattedTime } from "@/utils/formatting/dates.js";

export default {
  name: "UserViewComplaintsProfile",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawEmptyState,
    SuawTable,
    SuawTableItem,
    SuawButton,
    SuawTextInput
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      resultUserView: {
        id: "",
        complaints_profiles_reporting: [
          // {
          //   id: "",
          //   comment: "",
          //   created_at: "",
          //   reported_user: {
          //     id: "",
          //     first_name: "",
          //     last_name: "",
          //     avatar_color: "",
          //     avatar_url: "",
          //     initials: ""
          //   }
          //   complaints_profiles_resolution {
          //     id
          //   }
          // }
        ],
        complaints_profiles: [
          // {
          //   id: "",
          //   comment: "",
          //   created_at: "",
          //   reporting_user: {
          //     id: "",
          //     first_name: "",
          //     last_name: "",
          //     avatar_color: "",
          //     avatar_url: "",
          //     initials: ""
          //   }
          //   complaints_profiles_resolution {
          //     id
          //   }
          // }
        ]
      },
      resolvingComplaints: [],
      resolutionComment: null
    };
  },
  apollo: {
    resultUserView: {
      query: GetUserViewForAdminComplaintsProfile,
      variables() {
        return {
          userId: this.userId
        };
      },
      skip() {
        return !this.userId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultUserView.id !== "";
    },
    complaintMadeTableItems() {
      return this.resultUserView.complaints_profiles_reporting && this.resultUserView.complaints_profiles_reporting.length > 0
        ? this.complaintMadeTableProps(this.resultUserView.complaints_profiles_reporting)
        : [];
    },
    complaintReceivedTableItems() {
      return this.resultUserView.complaints_profiles && this.resultUserView.complaints_profiles.length > 0
        ? this.complaintReceivedTableProps(this.resultUserView.complaints_profiles.filter(c => !this.resolvingComplaints.find(r => r.id === c.id)))
        : [];
    },
    resolvingTableItems() {
      return this.resolvingComplaints.length > 0
        ? this.resolvingTableProps(this.resultUserView.complaints_profiles.filter(c => this.resolvingComplaints.find(r => r.id === c.id)))
        : [];
    }
  },
  methods: {
    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formattedTime(d)}`;
    },
    complaintMadeTableProps(complaintMadeItems) {
      return complaintMadeItems.map(x => ({
        id: x.id,
        userId: x.user.id,
        avatar: x.user.avatar_url ?? x.user.initials,
        color: x.user.avatar_url ? "picture" : x.user.avatar_color,
        contents: [
          {
            text: `${x.user.first_name} ${x.user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          }
        ],
        button: x.complaints_profiles_resolution ? "Resolved" : "View Profile"
      }));
    },
    onComplaintMadeViewClick(item) {
      this.$router.push({ name: "UserView", params: { userId: item.userId } });
    },
    complaintReceivedTableProps(complaintReceivedItems) {
      return complaintReceivedItems.map(x => ({
        id: x.id,
        userId: x.reporting_user.id,
        avatar: x.reporting_user.avatar_url ?? x.reporting_user.initials,
        color: x.reporting_user.avatar_url ? "picture" : x.reporting_user.avatar_color,
        contents: [
          {
            text: `${x.reporting_user.first_name} ${x.reporting_user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          }
        ],
        button: x.complaints_profiles_resolution ? "Resolved" : "View Complainer",
        buttonCritical: x.complaints_profiles_resolution ? null : "Resolve"
      }));
    },
    onComplaintReceivedViewClick(item) {
      this.$router.push({ name: "UserView", params: { userId: item.userId } });
    },
    onComplaintReceivedResolveClick(item) {
      this.resolvingComplaints.push(item);
    },
    resolvingTableProps(complaintReceivedItems) {
      return complaintReceivedItems.map(x => ({
        id: x.id,
        userId: x.reporting_user.id,
        avatar: x.reporting_user.avatar_url ?? x.reporting_user.initials,
        color: x.reporting_user.avatar_url ? "picture" : x.reporting_user.avatar_color,
        contents: [
          {
            text: `${x.reporting_user.first_name} ${x.reporting_user.last_name} @ ${this.formatDateAt(x.created_at)}`,
            subtext: x.comment
          }
        ],
        button: "Undo"
      }));
    },
    onUndoClick(item) {
      const i = this.resolvingComplaints.findIndex(c => c.id === item.id);
      if (i !== -1) {
        this.resolvingComplaints.splice(i, 1);
      }
    },
    async resolveComplaint() {
      var ids = this.resolvingComplaints.map(c => c.id);
      const result = await UserApi.resolveComplaintProfile(ids, this.resolutionComment);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSaveResolution() {
      if (!this.resolutionComment) {
        this.$root.$emit("universal-error-message", "A resolution comment is required.");
        return;
      } else {
        const result = await this.resolveComplaint();
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        } else {
          this.$root.$emit("universal-success-message", "Complaint(s) resolved.");
          this.$apollo.queries.resultUserView.refetch();
          this.resolvingComplaints = [];
          this.resolutionComment = null;
        }
      }
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawInputGroup v-if="complaintReceivedTableItems.length > 0" direction="column">
      <SuawHeading content="Profile Complaints Received" level="5" />
      <SuawTable>
        <SuawTableItem
          v-for="item in complaintReceivedTableItems"
          :key="item.id"
          :item="item"
          @message-click="onComplaintReceivedViewClick"
          @report-click="onComplaintReceivedResolveClick"
        />
      </SuawTable>
    </SuawInputGroup>
    <SuawInputGroup v-if="resolvingComplaints.length > 0" direction="column">
      <SuawTextInput
        id="resolutionComment"
        v-model="resolutionComment"
        type="text"
        label="Profile Complaint Resolution Comment"
        is-required
        placeholder="Explain how you resolved the Profile complaint(s) listed below."
      />
      <SuawTable>
        <SuawTableItem v-for="item in resolvingTableItems" :key="item.id" :item="item" @message-click="onUndoClick" />
      </SuawTable>
      <SuawButton
        v-if="resolvingComplaints.length > 0"
        icon-left="IconDone"
        button-text="Save Resolution"
        type="primary"
        class="suaw-button--pull-left"
        size="medium"
        @click="onSaveResolution"
      ></SuawButton>
    </SuawInputGroup>
    <SuawHeading v-else content="No Profile Complaints Received" level="6" />
    <SuawInputGroup v-if="complaintMadeTableItems.length > 0" direction="column">
      <SuawHeading content="Profile Complaints Made" level="5" />
      <SuawTable>
        <SuawTableItem v-for="item in complaintMadeTableItems" :key="item.id" :item="item" @message-click="onComplaintMadeViewClick" />
      </SuawTable>
    </SuawInputGroup>
    <SuawHeading v-else content="No Profile Complaints Made" level="6" />
  </SuawInputGroup>
</template>

<style lang="css" scoped></style>
